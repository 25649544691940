var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isMobile())?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.pagination,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-dialog',{attrs:{"persistent":"","max-width":"400px","click-outside-to-close":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-list-item',_vm._g({on:{"click":function($event){return _vm.showPasswordDialog(item)}}},on),[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-pencil-outline")]),_vm._v(" Change Password ")],1)]}}],null,true),model:{value:(item.passwordDialog),callback:function ($$v) {_vm.$set(item, "passwordDialog", $$v)},expression:"item.passwordDialog"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"400px"}},[_c('v-card-title',{staticClass:"text-center"},[_c('span',{staticClass:"headline"},[_vm._v("Change Password for: "),_c('br'),_vm._v(" "+_vm._s(item.email))])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"New Password","append-icon":_vm.passwordMask ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.passwordMask ? 'password' : 'text',"error-messages":_vm.passwordDialogErrors},on:{"click:append":_vm.togglePasswordMask},model:{value:(item.newPassword),callback:function ($$v) {_vm.$set(item, "newPassword", $$v)},expression:"item.newPassword"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.hidePasswordDialog(item)}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","loading":item.isSubmitting},on:{"click":function($event){return _vm.submitPassword(item)}}},[_vm._v(" Ok ")])],1),(item.isSubmitting)?_c('v-progress-linear',{staticClass:"my-4",attrs:{"color":"primary","indeterminate":""}}):_vm._e()],1)],1),_c('router-link',{attrs:{"to":{
              name: 'UserShops',
              params: {
                userId: item.id,
                userName:
                  item.userProfiles != null && item.userProfiles.length > 0
                    ? item.userProfiles[0].fullName
                    : item
              }
            }}},[_c('v-list-item',[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-cart")]),_vm._v(" Edit shop ")],1)],1),(_vm.currentUser.superAdmin)?_c('v-list-item',{on:{"click":function($event){return _vm.openChangeRoleModal(item)}}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-account")]),_vm._v(" Change Roles ")],1):_vm._e()],1)],1)]}},{key:"item.username",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.userProfiles != null && item.userProfiles.length > 0 ? item.userProfiles[0].fullName : "")+" ")])]}},{key:"item.categoryOverride",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50"},on:{"click":function($event){return _vm.toggleUserCategory(item.id, item.categoryOverride)}},model:{value:(item.categoryOverride),callback:function ($$v) {_vm.$set(item, "categoryOverride", $$v)},expression:"item.categoryOverride"}})]}},{key:"item.category",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_c('v-select',{staticClass:"type",attrs:{"items":_vm.categories,"item-text":"name","item-value":"id","single-line":"","return-object":"","disabled":_vm.loading},on:{"change":function($event){return _vm.changeCategory(item.id, item.categoryId)}},model:{value:(item.categoryId),callback:function ($$v) {_vm.$set(item, "categoryId", $$v)},expression:"item.categoryId"}})],1)]}},{key:"item.city",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.userProfiles != null && item.userProfiles.length > 0 && item.userProfiles[0].city != null ? item.userProfiles[0].city.name : "")+" ")])]}},{key:"item.shopList",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopList.length > 0 ? item.shopList[0].name : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(item.shopList.length > 1)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.shopList),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)]}},{key:"item.orderCount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderCount)+" ")]}},{key:"item.activated",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50"},on:{"click":function($event){return _vm.activateUser(item.id, item.activated)}},model:{value:(item.activated),callback:function ($$v) {_vm.$set(item, "activated", $$v)},expression:"item.activated"}})]}},{key:"item.suspended",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50"},on:{"click":function($event){return _vm.suspendUser(item.id, item.suspended)}},model:{value:(item.suspended),callback:function ($$v) {_vm.$set(item, "suspended", $$v)},expression:"item.suspended"}})]}},{key:"item.vip",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50"},on:{"click":function($event){return _vm.toggleVip(item.id, item.vipMember)}},model:{value:(item.vipMember),callback:function ($$v) {_vm.$set(item, "vipMember", $$v)},expression:"item.vipMember"}})]}},{key:"item.loyal",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50"},on:{"click":function($event){return _vm.toggleLoyal(item.id, item.loyaltyMember)}},model:{value:(item.loyaltyMember),callback:function ($$v) {_vm.$set(item, "loyaltyMember", $$v)},expression:"item.loyaltyMember"}})]}},{key:"item.influencer",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#4CAF50"},on:{"click":function($event){return _vm.toggleUserInfluencer(item.id, item.influencer)}},model:{value:(item.influencer),callback:function ($$v) {_vm.$set(item, "influencer", $$v)},expression:"item.influencer"}})]}},{key:"item.profile",fn:function(ref){
            var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"indigo","size":"24"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.goToProfileDialog(item.id)}}},[_vm._v("mdi-account-circle ")])],1)]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-text-field',{attrs:{"label":"ID","type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})],1),_c('td',[_c('v-text-field',{attrs:{"label":"Name","type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})],1),_c('td',[_c('v-text-field',{attrs:{"label":"Email","type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('td',{attrs:{"colspan":"2"}},[_c('v-select',{attrs:{"label":"Category","dense":"","hide-details":"","items":_vm.categories,"item-text":"name","item-value":"id"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('td',[_c('v-datetime-picker',{attrs:{"label":_vm.$t('date.from'),"time-format":"HH:mm:ss"},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('v-datetime-picker',{attrs:{"label":_vm.$t('date.to'),"time-format":"HH:mm:ss"},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),_c('td',[_c('v-autocomplete',{attrs:{"items":_vm.userCities,"loading":_vm.citiesIsLoading,"search-input":_vm.search,"clearable":"","item-text":"name","item-value":"id","label":"City"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("cities")])])],1)]},proxy:true},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}})],1)]]}}],null,false,2292246050),model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1),_c('td',[_c('v-select',{attrs:{"label":"Activated","items":_vm.userActivatedList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.userActivated),callback:function ($$v) {_vm.userActivated=$$v},expression:"userActivated"}})],1),_c('td',[_c('v-select',{attrs:{"label":"Suspended","items":_vm.userSuspendedList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.userSuspended),callback:function ($$v) {_vm.userSuspended=$$v},expression:"userSuspended"}})],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-select',{attrs:{"label":"Affiliate","items":_vm.userInfluencerList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.userInfluencer),callback:function ($$v) {_vm.userInfluencer=$$v},expression:"userInfluencer"}})],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Shop","type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveUsers();}},model:{value:(_vm.shopName),callback:function ($$v) {_vm.shopName=$$v},expression:"shopName"}})],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.retrieveUsers();}}},[_vm._v("mdi-magnify ")])],1),_c('v-spacer',{staticClass:"pt-2 pb-2"}),_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1)])]},proxy:true}],null,false,1472674710)}),_c('div',{staticClass:"table-footer-prepend d-flex pl-2 align-center"},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"loading":_vm.downloadingReport,"disabled":_vm.downloadingReport,"color":"green darken-1"},on:{"click":function($event){_vm.loader = 'downloadingReport';
        _vm.downloadReport();}}},[_vm._v(" Users Report "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file-excel ")])],1)],1),_c('ProfileDialog',{attrs:{"userProfile":_vm.userProfile},on:{"close":function($event){_vm.showProfileDialog = false}},model:{value:(_vm.showProfileDialog),callback:function ($$v) {_vm.showProfileDialog=$$v},expression:"showProfileDialog"}}),(_vm.currentUser.superAdmin)?_c('ChangeRoleDialog',{ref:"changeRoleDialog"}):_vm._e()],1):(_vm.isMobile())?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersMobile,"items":_vm.users,"options":_vm.pagination,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.userProfiles != null && item.userProfiles.length > 0 ? item.userProfiles[0].fullName : "")+" ")])]}},{key:"item.city",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.userProfiles != null && item.userProfiles[0].city != null ? item.userProfiles[0].city.name : "")+" ")])]}},{key:"item.shopList",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopList.length > 0 ? item.shopList[0].name : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(item.shopList.length > 1)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.shopList),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)]}},{key:"item.activated",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{on:{"click":function($event){return _vm.activateUser(item.id, item.activated)}},model:{value:(item.activated),callback:function ($$v) {_vm.$set(item, "activated", $$v)},expression:"item.activated"}})]}},{key:"item.profile",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"indigo","size":"20"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.goToProfileDialog(item.id)}}},[_vm._v("mdi-account-circle ")])],1)]}},{key:"body.prepend",fn:function(){return [_c('div',{staticClass:"filter"},[_c('div',[_c('v-text-field',{attrs:{"type":"text","label":"username"},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})],1),_c('div',[_c('v-text-field',{attrs:{"type":"text","label":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',[_c('v-select',{attrs:{"items":_vm.userActivatedList,"dense":"","hide-details":"","label":"activation status","aria-label":"activated"},model:{value:(_vm.userActivated),callback:function ($$v) {_vm.userActivated=$$v},expression:"userActivated"}})],1),_c('div',{staticClass:"search"},[_c('v-row',[_c('v-col',{staticStyle:{"padding-right":"0px","padding-left":"0px","max-width":"24px","margin-right":"10px"}},[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                    _vm.retrieveUsers();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('v-col',{staticStyle:{"padding-right":"0px","padding-left":"0px","max-width":"24px","margin-right":"10px"}},[_c('v-avatar',{attrs:{"color":"grey","size":"24"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                    _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1)],1)],1)])]},proxy:true}])})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }