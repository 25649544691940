<template>
  <v-dialog v-model="isOpen" max-width="400px">
    <div class="card card-custom card-stretch">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label  text-dark">
            Change User Role for {{ user ? user.username || user.email : "N/A" }}
          </span>
        </h3>
      </div>

      <div class="card-body border-0">
        <v-select
          :items="userRoleList"
          v-model="activeRoles"
          multiple
          label="User Roles"
          item-value="id"
          item-text="name"
        ></v-select>
      </div>

      <div class="d-flex justify-content-end pb-5 px-5">
        <v-btn class="ml-4" @click="close()">
          {{ $t("common.cancel") }}
        </v-btn>
        <v-btn color="primary" class="ml-4" @click="save()">
          {{ $t("common.save") }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import RoleService from "@/core/services/role.service";

export default {
  name: "change-role-dialog",
  data() {
    return {
      isOpen: false,
      userRoleList: [],
      activeRoles: [],
      user: null
    };
  },
  methods: {
    async open(user) {
      console.log(user);
      this.user = user;
      this.userRoleList = await RoleService.getAllRoles();
      this.isOpen = true;
    },
    close() {
      this.user = null;
      this.isOpen = false;
    },
    async save() {
      console.log(this.user, this.activeRoles);
      await RoleService.saveUserRole();
      this.close();
    }
  }
};
</script>
