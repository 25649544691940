const RoleService = {
  async getAllRoles() {
    return [
      {
        id: 1,
        name: "Member",
        description: "Regular members"
      },
      {
        id: 2,
        name: "Administrator",
        description: "User have full admin privileges"
      },
      {
        id: 3,
        name: "Superadmin",
        description: "Super administrator"
      },
      {
        id: 4,
        name: "Partner",
        description: "ooblee partners"
      }
    ];
  },
  async saveUserRole() {
    console.log("Saving user role");
  }
};

export default RoleService;
